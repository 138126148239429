@import '../../../scss/common';

.ikt-ws_dropdown-image {
  position: relative;
  font-weight: normal;
  font-size: 14px;
  width: 233px;

  &.ikt-ws_is-active {
    .ikt-ws_dropdown-image-menu {
      display: flex;
    }

    .ikt-ws_dropdown-image-trigger {
      button.ikt-ws_dropdown-image-button {
        border: 1px solid $dropdownActiveStrokeColor !important;
      }
    }
  }

  &.error {
    .ikt-ws_dropdown-image-trigger {
      button.ikt-ws_dropdown-image-button {
        border-color: #9d0006 !important;
      }
    }
  }

  &.disabled {
    .ikt-ws_dropdown-trigger {
      button.ikt-ws_dropdown-image-button {
        background-color: $dropdownDisabledStrokeColor;
        border: 1px solid $dropdownDisabledPlaceholderTextColor !important;

        .ikt-ws_dropdown-image-button-text {
          color: $dropdownDisabledMainTextColor;
        }
      }
    }
  }

  .ikt-ws_dropdown-image-trigger {
    width: 100%;

    button.ikt-ws_dropdown-image-button {
      display: flex;
      position: relative;
      align-items: center;
      padding: 9px 34px 9px 41px;
      text-align: left;
      width: 100%;
      outline: unset;
      background-color: #ffffff;
      border: 1px solid #bfd9ef;
      border-radius: 20px;
      cursor: pointer;

      &:hover {
        border: 1px solid $inputHoverStrokeColor;
      }

      .ikt-ws_dropdown-image-button-text {
        color: $dropdownMainTextColor;
        font-size: 14px;
      }

      .ikt-ws_dropdown-image-chevron {
        position: absolute;
        display: block;
        top: 50%;
        right: 11px;
        transform: translateY(-50%);
        width: auto;
        height: 8px;
      }
    }
  }

  .ikt-ws_dropdown-image-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    min-width: 70px;
    padding-top: 4px;
    z-index: 20;
  }

  .ikt-ws_dropdown-image-content {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 8px 4px rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.02);
    padding: 10px 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;

    .ikt-ws_dropdown-image-item {
      position: relative;
      display: block;
      text-align: left;
      color: #4a4a4a;
      font-size: 14px;
      line-height: 1.5;
      padding: 6px 16px 6px 41px;
      cursor: pointer;
    }

    .ikt-ws_dropdown-image-item:hover {
      background-color: #f5f5f5;
      color: #0a0a0a;
    }
  }

  .ikt-ws_dropdown-image-image {
    position: absolute;
    top: 50%;
    left: 7px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    &.placeholder {
      background-color: #c2c6ca;
      font-size: 14px;
      color: #ffffff;
      line-height: 18px;
    }
  }
}
