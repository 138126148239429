@import '../../../../scss/common';

.ikt-ws_shop-filter-distance-content {
  display: none;
  flex-direction: column;
  color: #000;
  padding-top: 5px;

  .ikt-ws_shop-filter-any-item {
    display: flex;
    position: relative;
    align-items: baseline;
    cursor: pointer;
    margin-right: 4px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 20px;
    color: $shopFilterMainItemColor;

    &:hover .ikt-ws_shop-distance-filter-distance-any-item-text {
      color: #000;
    }

    &.ikt-ws_is-active {
      .ikt-ws_shop-distance-filter-distance-any-item-text {
        color: #000;
      }

      .ikt-ws_shop-distance-filter-left-side-checkmark {
        display: flex;
      }
    }

    .ikt-ws_shop-distance-filter-distance-any-item-text {
      cursor: pointer;
      padding-left: 20px;
    }

    .ikt-ws_shop-distance-filter-left-side-checkmark {
      display: none;
      position: absolute;
      top: 5px;
      left: 0;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      border: 3px solid #000;
    }
  }

  .ikt-ws_shop-distance-filter-bottom {
    display: flex;
    position: relative;
    align-items: center;
    padding-left: 16px;

    &.ikt-ws_is-active {
      .ikt-ws_shop-distance-filter-left-side-checkmark {
        display: flex;
      }
    }

    .ikt-ws_shop-distance-filter-hyphen {
      position: relative;
      top: 2px;
      width: 12px;
      height: 1px;
      background-color: #c2c6ca;
      margin: 0 10px;
    }

    .ikt-ws_shop-distance-filter-input-to {
      background-color: transparent;
      outline: none;
      font-size: 14px;
      line-height: 18px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #c2c6ca;
      width: 147px;
      padding: 4px 4px;
    }

    input[type='number'].ikt-ws_shop-distance-filter-input-from::-webkit-inner-spin-button,
    input[type='number'].ikt-ws_shop-distance-filter-input-from::-webkit-outer-spin-button,
    input[type='number'].ikt-ws_shop-distance-filter-input-to::-webkit-inner-spin-button,
    input[type='number'].ikt-ws_shop-distance-filter-input-to::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'].ikt-ws_shop-distance-filter-input-from,
    input[type='number'].ikt-ws_shop-distance-filter-input-to {
      -moz-appearance: textfield;
    }

    .ikt-ws_shop-distance-filter-input-from::placeholder,
    .ikt-ws_shop-distance-filter-input-to::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-size: 14px;
      color: #636363;
      opacity: 1; /* Firefox */
    }

    .ikt-ws_shop-distance-filter-input-from:-ms-input-placeholder,
    .ikt-ws_shop-distance-filter-input-to:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: 14px;
      color: #636363;
    }

    .ikt-ws_shop-distance-filter-input-from::-ms-input-placeholder,
    .ikt-ws_shop-distance-filter-input-from::-ms-input-placeholder {
      /* Microsoft Edge */
      font-size: 14px;
      color: #636363;
    }

    .ikt-ws_shop-distance-filter-distance-checkmark {
      display: flex;
      position: relative;
      top: -1px;
      right: -7px;
      align-items: center;
      justify-content: center;
      width: 98px;
      height: 25px;
      background-color: #e9eaeb;
      border-radius: 20px;
      padding: 0 5px;
      margin-left: 5px;
      font-size: 12px;
      span.metric {
        font-size: 9px;
        padding-left: 2px;
        font-weight: bold;
      }
    }

    .ikt-ws_shop-distance-filter-left-side-checkmark {
      display: none;
      position: absolute;
      top: 8px;
      left: 0;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      border: 3px solid #000;
    }
  }
}
