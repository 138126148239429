.ikt-ws_counter-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 84px;
  height: 38px;
  padding: 0 30px;
  border: 1px solid #e9eaeb;
  border-radius: 24px;
  background-color: #f5f6f7;

  &.hover {
    border-color: #c3c9cc;
  }

  .ikt-ws_counter-dec-btn {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    cursor: pointer;
    padding: 9px 3px;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
  }

  .ikt-ws_counter-inc-btn {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    cursor: pointer;
    padding: 9px 3px;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
  }
}
