.ikt-ws_cabinet-builder-page-container {
  .ikt-ws_cabinet-builder-page-top {
    position: relative;
    margin: 20px;
    height: calc(100vh - 230px);
    display: flex;
    align-items: center;
    background-color: #212122;

    .cabinet-maker-iframe-wrapper {
      height: 498px;
      width: 100%;
      overflow: hidden;
      .cabinet-maker-iframe {
        height: 600px !important;
      }
    }

    .ikt-ws_cabinet-builder-page-save-btn {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 122px;
      height: 38px;
      padding: 0;
    }
  }

  .ikt-ws_cabinet-builder-page-bottom {
    display: flex;
    justify-content: space-between;
    padding: 24px 120px;
    background-color: #e9eaeb;

    .ikt-ws_cabinet-builder-cancel-btn,
    .ikt-ws_cabinet-builder-next-btn {
      width: 122px;
      height: 38px;
      padding: 0;
    }
  }
}
