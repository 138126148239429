.accordion {
  border: 1px solid #bfd9ef;
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 12px;

  .accordion-header {
    min-height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 32px;
    padding: 19px 32px;
    border-radius: 12px;
    color: #2f2f2f;
    cursor: pointer;
  }

  .header-opened {
    background: #f4faff;
  }

  .accordion-body {
    background: #f4faff;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    padding: 19px 32px;
    border-radius: 12px;
    color: #686868;
  }

  .opened {
    transform: rotate(180deg);
  }
}
