@import '../../../scss/common';

.ikt-ws_button.blue {
  background-color: $blueMainBgColor;
  color: #fff;
}

.ikt-ws_button.blue:hover {
  background-color: $blueHoverBgColor;
  color: #fff;
}

.ikt-ws_button.blue.disabled,
.ikt-ws_button.blue.disabled:hover {
  background-color: rgba($blueMainBgColor, 0.5);
  cursor: default;
}

.button-blue-bigger-width {
  min-width: 300px !important;
}
