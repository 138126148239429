@import 'common';
@import '~react-toastify/dist/ReactToastify.min.css';
@import 'notifications';

@import 'layout';
@import 'home';
@import 'cart';
@import 'order';
@import 'cabinet';

* {
  font-family: 'Montserrat', sans-serif;
}

html,
body,
#root {
  margin: 0;
  height: 100%;
}
