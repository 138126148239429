@import '../../../../../../scss/common';

.ikt-ws_checkbox-manufacturer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 38px;
  cursor: pointer;
  border-radius: 20px;
  background-color: #e0edef;

  &:hover {
    background-color: #1da6b2;
  }

  &.checked {
    background-color: #1899a5;
  }
}
