.ikt-ws_make-cabinet-get-started-container {
  .ikt-ws_make-cabinet-get-started-wrapper {
    padding: 84px 120px 76px 120px;

    & > .columns {
      flex-wrap: wrap;
    }

    .ikt-ws_make-cabinet-get-started-heading {
      font-size: 40px;
      color: #000000;
      line-height: 49px;
      font-weight: bold;
      margin-bottom: 33px;
    }

    .ikt-ws_make-cabinet-get-started-description {
      font-size: 14px;
      color: #000000;
      line-height: 25px;
      margin-bottom: 39px;
    }

    .ikt-ws_make-cabinet-get-started-btn {
      width: 222px;
      height: 42px;
      padding: 0;
    }

    .ikt-ws_make-cabinet-get-started-right-col {
      padding-left: 106px !important;
    }
  }

  .sign-up-container {
    margin-top: 0;
  }
}

@media screen and (max-width: 1404px) {
  .ikt-ws_make-cabinet-get-started-container {
    .ikt-ws_make-cabinet-get-started-wrapper {
      .ikt-ws_make-cabinet-get-started-btn {
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .ikt-ws_make-cabinet-get-started-container {
    .ikt-ws_make-cabinet-get-started-wrapper {
      padding-left: 30px;
      padding-right: 30px;

      .ikt-ws_make-cabinet-get-started-right-col {
        display: flex;
        justify-content: center;
        padding-left: 0 !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .ikt-ws_make-cabinet-get-started-container {
    .ikt-ws_make-cabinet-get-started-wrapper {
      .ikt-ws_make-cabinet-get-started-btn {
        width: 100%;
      }
    }
  }
}
