.ikt-ws_login-container {
    visibility: hidden;
    position: absolute;
    top: 0;
    right: -480px;
    width: 480px;
    height: 100vh;
    background-color: #ffffff;
    z-index: 101;
    -webkit-transition: none;
    transition: none;

    &.ikt-ws_is-active.is-animate {
        visibility: visible;
        right: 0;
        -webkit-transition: right .5s;
        transition: right .5s;
    }

    &.ikt-ws_is-active {
        visibility: visible;
        right: 0;
    }

    .ikt-ws_login-wrapper {
        height: 100%;
        overflow-y: auto;

        .ikt-ws_login-top {
            padding: 136px 108px 113px 108px;
            text-align: center;

            .ikt-ws_login-text {
                font-size: 32px;
                color: #000000;
                line-height: 39px;
                font-weight: bold;
                margin-bottom: 33px;
            }

            .ikt-ws_login-email {
                padding: 0;
                margin-bottom: 14px;
            }

            .ikt-ws_login-password {
                padding: 0;
                margin-bottom: 14px;
            }

            .ikt-ws_login-login-btn {
                width: 100%;
                height: 38px;
                font-size: 14px;
                padding: 0;
                margin-bottom: 16px;
            }

            .ikt-ws_login-forgot-password {
                width: 100%;
                font-size: 14px;
                padding: 8px 8px;
                margin-bottom: 45px;
            }
        }

        .ikt-ws_login-register-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: #f4faff;
            padding: 91px 65px 98px 65px;

            .ikt-ws_login-register-text {
                font-size: 32px;
                color: #000000;
                line-height: 39px;
                font-weight: bold;
                margin-bottom: 28px;
            }

            .ikt-ws_login-register-btn {
                background-color: #9ac3e5;
                color: white;
                width: 264px;
                height: 38px;
                padding: 0;
            }
        }
    }
}
