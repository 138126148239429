$mainInfoTextElementMinWidth: 70px;

.ikt-ws_product-details-page-container {
  .ikt-ws_product-details-page-general-info {
    display: flex;
    flex-direction: column;
    margin-top: 26px;
    padding: 0 120px;

    .ikt-ws_product-details-page-general-info-top {
      display: flex;
      flex-direction: row;

      .ikt-ws_product-details-page-images-section {
        width: 580px;

        .ikt-ws_product-details-page-big-image {
          width: 100%;
          height: 446px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          border-radius: 7px;
        }
      }

      .ikt-ws_product-details-page-main-info {
        padding: 30px 0 0 65px;
        max-width: calc(100% - 580px);

        .ikt-ws_product-details-product-title {
          font-size: 40px;
          color: #000000;
          line-height: 49px;
          font-weight: 700;
        }

        .ikt-ws_product-details-product-material-n-style {
          display: flex;
          align-items: flex-end;
          margin-top: 18px;

          .ikt-ws_product-details-product-material {
            font-size: 18px;
            color: rgba(#000, 0.5);
            line-height: 22px;
          }

          .ikt-ws_product-details-product-style {
            display: flex;
            position: relative;
            top: -1px;
            align-items: center;
            margin-left: 13px;
            background-color: #9ac3e5;
            font-size: 14px;
            color: #ffffff;
            line-height: 14px;
            border-radius: 20px;
            padding: 4px 9px;
          }
        }

        .ikt-ws_product-details-colors {
          display: flex;
          align-items: center;
          margin-top: 18px;

          .ikt-ws_product-details-colors-text {
            position: relative;
            top: -5px;
            font-size: 14px;
            color: #000000;
            line-height: 18px;
            font-weight: bold;
            min-width: $mainInfoTextElementMinWidth;
          }

          .ikt-ws_product-details-colors-options {
            display: flex;
            flex-wrap: wrap;

            .ikt-ws_product-details-color-btn-content {
              display: inline-block;
              position: relative;
              cursor: pointer;
              width: 18px;
              height: 18px;
              line-height: 18px;
              margin-right: 9px;
              border-radius: 50%;
              margin-bottom: 10px;

              &:hover {
                .ikt-ws_product-details-color-btn-outer {
                  border-color: #000 !important;
                  background-color: #fff !important;
                }
              }

              .ikt-ws_product-details-color-btn-outer {
                position: absolute;
                top: 0;
                left: 0;
                width: 18px;
                height: 18px;
                line-height: 18px;
                border: 1px solid;
                border-radius: 50%;
                background-color: #fff;

                .ikt-ws_product-details-color-btn-inner {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  width: 11px;
                  height: 11px;
                  line-height: 11px;
                  border-radius: 50%;
                  border: unset;
                }
              }
            }
          }
        }

        .ikt-ws_product-details-sizes {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 18px;

          .ikt-ws_product-details-size-text {
            color: #000;
            font-size: 14px;
            font-weight: bold;
            min-width: $mainInfoTextElementMinWidth;
          }

          .ikt-ws_product-details-size-dropdown {
            max-width: 175px;

            .ikt-ws_dropdown_button {
              padding: 9px 10px;
            }
          }
        }

        .ikt-ws_product-details-quantity {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 20px;

          .ikt-ws_product-details-quantity-text {
            color: #000;
            font-size: 14px;
            font-weight: bold;
            min-width: $mainInfoTextElementMinWidth;
          }
        }

        .ikt-ws_product-details-departments {
          display: flex;
          flex-direction: column;
          margin-top: 25px;

          .ikt-ws_product-details-departments-text {
            color: #000;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
          }

          .ikt-ws_product-details-departments-chips {
            display: flex;
            flex-direction: row;

            .ikt-ws_product-details-departments-chips-item {
              display: flex;
              position: relative;
              top: -1px;
              align-items: center;
              margin-right: 11px;
              background-color: #9ac3e5;
              font-size: 14px;
              color: #ffffff;
              line-height: 14px;
              border-radius: 20px;
              padding: 4px 9px;
            }
          }
        }

        .ikt-ws_product-details-product-price {
          font-size: 40px;
          font-weight: bold;
          color: #000;
          line-height: 49px;
          margin-top: 39px;
        }

        .ikt-ws_product-details-add-to-cart-btn {
          width: 216px;
          font-size: 14px;
          padding-top: 8px;
          padding-bottom: 8px;
          margin-top: 22px;
        }
      }
    }
  }

  .ikt-ws_product-details-page-reviews {
    margin-top: 55px;
    padding-bottom: 46px;

    .ikt-ws_product-details-page-reviews-heading {
      font-size: 26px;
      color: #000000;
      line-height: 32px;
      padding-bottom: 22px;
      padding-left: 2px;
    }

    .ikt-ws_product-details-page-reviews-paragraph {
      font-size: 14px;
      color: #000000;
      line-height: 25px;
      padding-bottom: 20px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .ikt-ws_product-details-page-reviews-container {
    .ikt-ws_product-details-page-reviews-product-reviews {
      padding: 35px 147px 28px 120px;
      background-color: #f4faff;

      .ikt-ws_product-details-page-reviews-heading {
        display: flex;
        align-items: center;

        .ikt-ws_product-details-page-reviews-heading-text {
          font-size: 26px;
          color: #000000;
          line-height: 32px;
        }

        .ikt-ws_product-details-page-reviews-heading-reviews-amount {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          color: #fff;
          background-color: #1899a5;
          font-size: 14px;
          font-weight: bold;
          margin-left: 11px;
        }
      }
    }

    .ikt-ws_product-details-page-product-reviews-content {
      margin-top: 19px;

      .ikt-ws_product-details-page-product-reviews-item {
        display: flex;
        padding-bottom: 11px;

        &:last-child {
          padding-bottom: 0;
        }

        .ikt-ws_product-details-page-product-reviews-user-photo-wrapper {
          padding-right: 13px;

          .ikt-ws_product-details-page-product-reviews-user-photo {
            width: 68px;
            height: 68px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 50%;
          }
        }

        .ikt-ws_product-details-page-product-reviews-info-wrapper {
          .ikt-ws_product-details-page-product-reviews-user-name-n-timestamp {
            display: flex;
            padding-top: 10px;

            .ikt-ws_product-details-page-product-reviews-user-name {
              font-size: 14px;
              color: #000000;
              line-height: 18px;
              font-weight: bold;
            }

            .ikt-ws_product-details-page-product-reviews-timestamp {
              margin-left: 25px;
              font-size: 14px;
              color: rgba(#000000, 0.5);
              line-height: 18px;
              font-weight: bold;
            }
          }

          .ikt-ws_product-details-page-product-reviews-rate {
            display: flex;
            padding-top: 11px;

            .ikt-ws_product-details-page-product-reviews-rate-star {
              display: flex;
              margin-right: 6px;
            }
          }

          .ikt-ws_product-details-page-product-reviews-text {
            padding-top: 15px;
            font-size: 14px;
            color: #000000;
            line-height: 25px;
          }
        }
      }
    }

    .ikt-ws_product-details-page-product-reviews-post-review {
      display: flex;
      padding: 25px 147px 34px 120px;
      background-color: #f4faff;
      border-top: 1px solid #d0d5da;

      .ikt-ws_product-details-page-product-reviews-post-review-user-photo-wrapper {
        .ikt-ws_product-details-page-product-reviews-post-review-user-photo {
          width: 68px;
          height: 68px;
          border-radius: 50%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50% 50%;
        }
      }

      .ikt-ws_product-details-page-product-reviews-post-review-controls {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding-top: 11px;
        padding-left: 11px;

        .ikt-ws_product-details-page-product-reviews-post-review-heading {
          font-size: 14px;
          color: #000000;
          line-height: 18px;
          font-weight: bold;
          padding-bottom: 13px;
        }

        .ikt-ws_product-details-page-product-reviews-post-review-rate {
          display: flex;
          padding-bottom: 16px;

          .ikt-ws_product-details-page-product-post-review-rate-star {
            display: flex;
            margin-right: 6px;
            cursor: pointer;
          }
        }

        .ikt-ws_product-details-page-product-reviews-post-textarea {
          width: 100%;
          height: 101px;
          border: 1px solid #e9eaeb;
          background-color: #f5f6f7;
          border-radius: 15px;
          padding: 12px;
          font-size: 14px;
          font-family: 'Montserrat', sans-serif;
          outline: none;
          resize: none;
          margin-bottom: 10px;

          &::-webkit-input-placeholder {
            color: rgba(#000, 0.5);
          }

          &:-moz-placeholder {
            /* Firefox 18- */
            color: rgba(#000, 0.5);
          }

          &::-moz-placeholder {
            /* Firefox 19+ */
            color: rgba(#000, 0.5);
          }

          &:-ms-input-placeholder {
            color: rgba(#000, 0.5);
          }

          &::placeholder {
            color: rgba(#000, 0.5);
          }
        }

        .ikt-ws_product-details-page-add-review-btn {
          width: 216px;
          height: 29px;
          font-size: 14px;
          font-weight: bold;
          color: #ffffff;
          line-height: 18px;
          padding: 0;
        }
      }
    }
  }

  .ikt-ws_product-details-page-similar-products {
    padding: 56px 40px 28px 40px;

    .ikt-ws_product-details-page-similar-product-heading {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      color: #000000;
      line-height: 49px;
      font-weight: bold;
      padding-bottom: 46px;
    }
  }

  .sign-up-container {
    margin-top: 0;
  }

  .ikt-ws_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 192px);
    width: 100%;
  }
}
