@import '../../../../scss/common';

.ikt-ws_shop-filter-color-content {
  display: none;
  flex-direction: column;
  color: #000;
  padding-top: 10px;

  .ikt-ws_shop-color-filter-colors-wrapper {
    padding-left: 23px;
    margin-right: -5px;

    .ikt-ws_shop-color-filter-item {
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      margin-right: 9px;
    }

    .ikt-ws_shop-color-filter-item-content {
      display: inline-block;
      position: relative;
      cursor: pointer;
      width: 18px;
      height: 18px;
      line-height: 18px;
      margin-right: 9px;
      border-radius: 50%;

      &:hover {
        .ikt-ws_shop-color-filter-color-btn-outer {
          border-color: #000 !important;
          background-color: #fff !important;
        }
      }

      .ikt-ws_shop-color-filter-color-btn-outer {
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
        line-height: 18px;
        border: 1px solid;
        border-radius: 50%;
        background-color: #fff;

        .ikt-ws_shop-color-filter-color-btn-inner {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 11px;
          height: 11px;
          line-height: 11px;
          border-radius: 50%;
          border: unset;
        }
      }
    }
  }

  .ikt-ws_shop-filter-all-item {
    display: flex;
    position: relative;
    flex-direction: row;
    font-weight: bold;
    color: $shopFilterMainItemColor;
    padding-bottom: 9px;

    &.ikt-ws_is-active {
      .ikt-ws_shop-filter-all-item-checkmark {
        display: flex;
      }

      .ikt-ws_shop-filter-all-item-text {
        color: #000;
      }
    }

    .ikt-ws_shop-filter-all-item-checkmark {
      display: none;
      position: absolute;
      top: -3px;
      left: 0;
      align-items: flex-start;
      width: 12px;
      height: 20px;
      background-image: url('../../../../assets/images/menuIcons/checkmark-filter.svg');
      background-repeat: no-repeat;
      background-position: center 8px;
      margin-right: 10px;
    }

    .ikt-ws_shop-filter-all-item-text {
      width: 100%;
      cursor: pointer;
      padding-left: 20px;
      font-size: 14px;

      &:hover {
        color: #000;
      }
    }
  }
}
