@import '../../../scss/common';

.ikt-ws_dropdown {
  position: relative;
  font-weight: normal;
  font-size: 14px;
  width: 233px;

  &.ikt-ws_is-active {
    .ikt-ws_dropdown-menu {
      display: flex;
    }

    .ikt-ws_dropdown-trigger {
      button.ikt-ws_dropdown-button {
        border: 1px solid $dropdownActiveStrokeColor !important;
      }
    }
  }

  &.error {
    .ikt-ws_dropdown-trigger {
      button.ikt-ws_dropdown-button {
        border-color: #9d0006 !important;
      }
    }
  }

  &.disabled {
    .ikt-ws_dropdown-trigger {
      button.ikt-ws_dropdown-button {
        background-color: $dropdownDisabledStrokeColor;
        border: 1px solid $dropdownDisabledPlaceholderTextColor !important;

        .ikt-ws_dropdown-button-text {
          color: $dropdownDisabledMainTextColor;
        }
      }
    }
  }

  .ikt-ws_dropdown-trigger {
    width: 100%;

    button.ikt-ws_dropdown-button {
      display: flex;
      position: relative;
      align-items: center;
      padding: 9px 34px 9px 9px;
      text-align: left;
      width: 100%;
      outline: unset;
      background-color: $dropdownFillColor;
      border: 1px solid $dropdownMainStrokeColor;
      border-radius: 20px;
      cursor: pointer;

      &:hover {
        border: 1px solid $inputHoverStrokeColor;
      }

      .ikt-ws_dropdown-button-text {
        color: $dropdownMainTextColor;
        font-size: 14px;
      }

      .ikt-ws_dropdown-chevron {
        position: absolute;
        display: block;
        top: 50%;
        right: 11px;
        transform: translateY(-50%);
        width: auto;
        height: 8px;
      }
    }
  }

  .ikt-ws_dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    min-width: 70px;
    padding-top: 4px;
    z-index: 20;
  }

  .ikt-ws_dropdown-content {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 8px 4px rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.02);
    padding: 10px 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;

    .ikt-ws_dropdown-item {
      display: block;
      text-align: left;
      color: #4a4a4a;
      font-size: 14px;
      line-height: 1.5;
      padding: 6px 16px;
      cursor: pointer;
    }

    .ikt-ws_dropdown-item:hover {
      background-color: #f5f5f5;
      color: #0a0a0a;
    }
  }
}
