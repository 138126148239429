.ikt-ws_manufacturer-list-container {
  width: 100%;

  .ikt-ws_manufacturer-list-heading {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 21px;
    padding-right: 79px;

    .ikt-ws_manufacturer-list-heading-item {
      display: flex;
      cursor: pointer;

      &.active {
        .ikt-ws_manufacturer-list-heading-item-content {
          color: #000;

          img {
            display: block;
          }
        }

        &.ascending {
          .ikt-ws_manufacturer-list-heading-item-content {
            img {
              top: 2px;
              right: 0;
              transform: rotate(-45deg);
            }
          }
        }

        &.descending {
          .ikt-ws_manufacturer-list-heading-item-content {
            img {
              top: 8px;
              right: 0;
              transform: rotate(135deg);
            }
          }
        }
      }

      .ikt-ws_manufacturer-list-heading-item-content {
        display: table;
        position: relative;
        padding-right: 15px;
        font-size: 14px;
        color: rgba(#000000, 0.5);
        line-height: 18px;
        font-weight: bold;

        img {
          display: none;
          position: absolute;
          width: 10px;
          height: 10px;
        }
      }
    }
    .ikt-ws_manufacturer-list-heading-manufacturer {
      width: 35%;
    }

    .ikt-ws_manufacturer-list-heading-delivery-date {
      width: 17%;
    }

    .ikt-ws_manufacturer-list-heading-delivery-price {
      min-width: 17%;
    }

    .ikt-ws_manufacturer-list-heading-cabinet-price {
      width: 17%;
    }
  }
}
