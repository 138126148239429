@import '../../../scss/common';

.ikt-ws_product-card {
  display: inline-block;
  vertical-align: top;
  border-radius: 7px;
  width: 233px;

  &:hover {
    .ikt-ws_product-card-bottom {
      background-color: #f4faff;

      .ikt-ws_product-card-more-info-btn {
        display: flex;
      }
    }
  }

  .ikt-ws_product-card-top {
    position: relative;

    .ikt-ws_product-card-image {
      display: flex;
      width: 100%;
      height: 196px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      border-radius: 7px 7px 0 0;
    }

    .ikt-ws_product-card-style {
      position: absolute;
      bottom: 8px;
      left: 8px;
      padding: 5px 9px;
      border-radius: 17px;
      font-size: 14px;
      line-height: 18px;
      color: #ffffff;
      background-color: #9ac3e5;
    }
  }
  .ikt-ws_product-card-bottom {
    position: relative;
    padding: 14px 10px 47px 10px;
    border-radius: 0 0 7px 7px;

    .ikt-ws_product-card-title {
      font-size: 16px;
      color: #000000;
      line-height: 19px;
      font-weight: bold;
    }

    .ikt-ws_product-card-price {
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
      color: #000000;
      margin-top: 9px;
    }

    .ikt-ws_product-card-material-n-size {
      font-size: 14px;
      color: rgba(#000, 0.5);
      line-height: 18px;
      margin-top: 2px;
    }

    .ikt-ws_product-card-more-info-btn {
      display: none;
      position: absolute;
      font-size: 14px;
      line-height: 18px;
      width: calc(100% - 20px);
      padding: 5px 10px;
      bottom: 10px;
      left: 10px;
    }

    .ikt-ws_product-card-colors {
      margin-top: 12px;
      margin-right: -3px;
      line-height: 25px;

      .ikt-ws_product-card-color-btn-content {
        display: inline-block;
        position: relative;
        cursor: pointer;
        width: 18px;
        height: 18px;
        line-height: 18px;
        margin-right: 9px;
        border-radius: 50%;

        &:hover {
          .ikt-ws_product-card-color-btn-outer {
            border-color: #000 !important;
            background-color: #fff !important;
          }
        }

        .ikt-ws_product-card-color-btn-outer {
          position: absolute;
          top: 0;
          left: 0;
          width: 18px;
          height: 18px;
          line-height: 18px;
          border: 1px solid;
          border-radius: 50%;
          background-color: #fff;

          .ikt-ws_product-card-color-btn-inner {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 11px;
            height: 11px;
            line-height: 11px;
            border-radius: 50%;
            border: unset;
          }
        }
      }
    }
  }
}
