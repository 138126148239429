// Button colors:

$goldMainBgColor: #b07635;
$goldHoverBgColor: #ca8f4d;
$tealMainBgColor: #1899a5;
$tealHoverBgColor: #1da6b2;
$blueMainBgColor: #9ac3e5;
$blueHoverBgColor: #AECFEA;
$shadedMainBgColor: #e7f4f6;
$shadedHoverBgColor: #e0f4f8;
$shadedMainTextColor: #1899a5;
$linkEmptyMainTextColor: #1899a5;
$linkEmptyHoverTextColor: #1da6b2;
$linkEmptyDisabledTextColor: rgba(#1899a5, 0.5);

// Input colors:

$inputMainTextColor: #000;
$inputFillColor: #f5f6f7;
$inputMainStrokeColor: #e9eaeb;
$inputPlaceholderTextColor: rgba(#000, 0.5);
$inputHoverStrokeColor: #c3c9cc;
$inputFocusStrokeColor: #1899a5;
$inputDisabledFillColor: rgba(#f5f6f7, 0.5);
$inputDisabledStrokeColor: rgba(#e9eaeb, 0.5);
$inputDisabledPlaceholderTextColor: rgba(#000, 0.25);
$inputDisabledMainTextColor: $inputDisabledPlaceholderTextColor;

// Dropdown colors:

$dropdownMainTextColor: $inputMainTextColor;
$dropdownFillColor: $inputFillColor;
$dropdownMainStrokeColor: $inputMainStrokeColor;
$dropdownHoverStrokeColor: $inputHoverStrokeColor;
$dropdownActiveStrokeColor: $inputFocusStrokeColor;
$dropdownDisabledStrokeColor: $inputDisabledStrokeColor;
$dropdownDisabledPlaceholderTextColor: $inputDisabledPlaceholderTextColor;
$dropdownDisabledMainTextColor: rgba($inputMainTextColor, 0.5);

// ShopFilter colors

$shopFilterMainItemColor: #636363;
$shopFilterStrokeColor: #bfd9ef;
$shopFilterFillColor: #f4faff;
