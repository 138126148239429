.ikt-ws_reset-password-container {
    visibility: hidden;
    position: absolute;
    top: 0;
    right: -480px;
    width: 480px;
    height: 100vh;
    background-color: #ffffff;
    z-index: 101;
    -webkit-transition: none;
    transition: none;

    &.ikt-ws_is-active.is-animate {
        visibility: visible;
        right: 0;
        -webkit-transition: right .5s;
        transition: right .5;
    }

    &.ikt-ws_is-active {
        visibility: visible;
        right: 0;
    }

    .ikt-ws_reset-password-wrapper {
        display: block;
        text-align: center;
        height: 100%;
        overflow-y: auto;
        padding: 166px 104px 0 104px;

        .ikt-ws_reset-password-text {
            font-size: 32px;
            color: #000000;
            line-height: 39px;
            font-weight: bold;
            margin-bottom: 67px
        }

        .ikt-ws_reset-password-email {
            margin-bottom: 20px;
            padding-bottom: 0;
        }

        .ikt-ws_reset-password-reset-password-btn {
            width: 100%;
            height: 38px;
            margin-bottom: 115px;
            padding: 0;
        }

        .ikt-ws_reset-password-back-to-login {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            padding: 6px 10px;
            margin: 0 auto 67px auto;

            &:hover {
                .ikt-ws_reset-password-back-to-login-text {
                    color: #1da6b2;
                }
            }

            .ikt-ws_reset-password-back-to-login-text {
                padding-left: 15px;
                font-size: 14px;
                color: #1899a5;
                line-height: 18px;
                font-weight: bold;
            }
        }
    }
}
