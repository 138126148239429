@import '../../../scss/common';

.ikt-ws_input-container {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 17px;

  &.error {
    .ikt-ws_input {
      border-color: #9d0006 !important;
    }
  }

  &.left-border-radius {
    .ikt-ws_input {
    }
  }

  &.right-border-radius {
    .ikt-ws_input {
    }
  }

  &.none-border-radius {
    .ikt-ws_input {
      border-radius: 0;
    }
  }

  &.right-border-radius {
    .ikt-ws_input {
      border-radius: 0 20px 20px 0;
    }
  }

  &.left-border-radius {
    .ikt-ws_input {
      border-radius: 20px 0 0 20px;
    }
  }

  .ikt-ws_input {
    outline: unset;
    padding: 9px 12px;
    border-radius: 20px;
    border: 1px solid $inputMainStrokeColor;
    background-color: $inputFillColor !important;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: $inputMainTextColor;
    line-height: 18px;
  }

  .ikt-ws_input:hover {
    border-color: $inputHoverStrokeColor;
  }

  .ikt-ws_input:focus {
    border-color: $inputFocusStrokeColor;
  }

  .ikt-ws_input[disabled] {
    color: $inputDisabledMainTextColor;
    border-color: $inputDisabledStrokeColor;
    background-color: $inputDisabledFillColor;
  }

  .ikt-ws_input[type='password'] {
    font-family: caption;
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 2px;
  }

  .ikt-ws_input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $inputPlaceholderTextColor;
    opacity: 1; /* Firefox */
    font-family: 'Montserrat', sans-serif;
    letter-spacing: normal;
    font-size: 14px;
  }

  .ikt-ws_input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $inputPlaceholderTextColor;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: normal;
    font-size: 14px;
  }

  .ikt-ws_input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $inputPlaceholderTextColor;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: normal;
    font-size: 14px;
  }

  .ikt-ws_input[disabled]::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $inputDisabledPlaceholderTextColor;
    opacity: 1; /* Firefox */
  }

  .ikt-ws_input[disabled]:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $inputDisabledPlaceholderTextColor;
  }

  .ikt-ws_input[disabled]::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $inputDisabledPlaceholderTextColor;
  }

  .ikt-ws_input-error-message {
    display: flex;
    position: absolute;
    left: 0;
    bottom: 0;
    justify-content: left;
    width: 100%;
    font-size: 12px;
    padding: 0 12px;
    color: #9d0006;
  }
}
