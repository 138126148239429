@import '../../../scss/common';

.ikt-ws_shop-filter {
  border: 1px solid $shopFilterStrokeColor;
  border-radius: 7px;
  min-width: 200px;
  background-color: $shopFilterFillColor;
  padding: 12px;
  margin-bottom: 8px;
  width: 233px;

  &.ikt-ws_is-active {
    .ikt-ws_shop-filter-text-content,
    .ikt-ws_shop-filter-color-content,
    .ikt-ws_shop-filter-chips-content,
    .ikt-ws_shop-filter-price-content,
    .ikt-ws_shop-filter-distance-content {
      display: flex;
    }
  }

  .ikt-ws_shop-filter-trigger {
    position: relative;
    cursor: pointer;

    .ikt-ws_shop-filter-trigger-title {
      padding-right: 25px;
      font-size: 16px;
      font-weight: bold;
      color: #000;
    }

    .ikt-ws_dropdown-custom-chevron {
      position: absolute;
      top: 8px;
      right: 3px;
    }
  }
}
