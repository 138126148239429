.ikt-ws_login-socials {
  .ikt-ws_login-socials-text {
    font-size: 20px;
    color: #000000;
    line-height: 24px;
    margin-bottom: 15px;
  }

  .ikt-ws_login-socials-icon-container {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;

    .ikt-ws_login-socials-icon {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }
}
