@import '../../../scss/common';

.ikt-ws_user-account-dropdown {
  position: relative;
  font-weight: normal;
  font-size: 14px;

  &.ikt-ws_is-active {
    .ikt-ws_user-account-dropdown-menu {
      display: flex;
    }

    .ikt-ws_user-account-dropdown-trigger {
      button.ikt-ws_user-account-dropdown-button {
        border: 1px solid $dropdownActiveStrokeColor !important;
      }
    }
  }

  .ikt-ws_user-account-dropdown-trigger {
    width: 100%;

    button.ikt-ws_user-account-dropdown-button {
      display: flex;
      position: relative;
      align-items: center;
      padding: 0;
      text-align: left;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      outline: unset;
      border: 1px solid $dropdownMainStrokeColor;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        border: 1px solid $inputHoverStrokeColor;
      }
    }
  }

  .ikt-ws_user-account-dropdown-menu {
    display: none;
    position: absolute;
    top: 50%;
    right: 50%;
    width: 132px;
    min-width: 70px;
    padding-top: 0;
    z-index: 20;
  }

  .ikt-ws_user-account-dropdown-content {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 8px 4px rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.02);
    padding: 11px 0;
    width: 100%;

    .ikt-ws_user-account-dropdown-item {
      display: block;
      text-align: left;
      color: #000;
      font-size: 14px;
      line-height: 1.5;
      padding: 11px 22px;
      cursor: pointer;
    }

    .ikt-ws_user-account-dropdown-item:hover {
      background-color: #f5f5f5;
      color: #4a4a4a;
    }
  }
}
