@import '../../../../scss/common';

.ikt-ws_shop-filter-text-content {
  display: none;
  flex-direction: column;
  color: #000;
  padding-top: 5px;

  .ikt-ws_shop-text-filter-item {
    display: flex;
    position: relative;
    padding: 4px 0;
    flex-direction: row;

    &.ikt-ws_is-active {
      .ikt-ws_shop-filter-all-item-checkmark {
        display: flex;
      }

      .ikt-ws_shop-text-filter-item-text {
        color: #000;
      }
    }

    .ikt-ws_shop-filter-all-item-checkmark {
      display: none;
      position: absolute;
      top: 1px;
      left: 0;
      align-items: flex-start;
      width: 12px;
      height: 20px;
      background-image: url('../../../../assets/images/menuIcons/checkmark-filter.svg');
      background-repeat: no-repeat;
      background-position: center 8px;
      margin-right: 10px;
      font-size: 14px;
    }

    .ikt-ws_shop-text-filter-item-text {
      width: 100%;
      cursor: pointer;
      color: $shopFilterMainItemColor;
      padding-left: 20px;
      font-size: 14px;

      &:hover {
        color: #000;
      }
    }
  }

  .ikt-ws_shop-filter-all-item {
    display: flex;
    position: relative;
    flex-direction: row;
    font-weight: bold;
    padding: 4px 0;
    color: $shopFilterMainItemColor;

    &.ikt-ws_is-active {
      .ikt-ws_shop-filter-all-item-checkmark {
        display: flex;
      }

      .ikt-ws_shop-filter-all-item-text {
        color: #000;
      }
    }

    .ikt-ws_shop-filter-all-item-checkmark {
      display: none;
      position: absolute;
      top: 1px;
      left: 0;
      align-items: flex-start;
      width: 12px;
      height: 20px;
      background-image: url('../../../../assets/images/menuIcons/checkmark-filter.svg');
      background-repeat: no-repeat;
      background-position: center 8px;
      margin-right: 10px;
    }

    .ikt-ws_shop-filter-all-item-text {
      width: 100%;
      cursor: pointer;
      padding-left: 20px;
      font-size: 14px;

      &:hover {
        color: #000;
      }
    }
  }
}
