@import '../../../scss/common';

.ikt-ws_dropdown-order {
  border: 1px solid #e9eaeb;
  border-radius: 7px;
  min-width: 200px;
  margin-bottom: 15px;

  &.ikt-ws_is-active {
    .ikt-ws_dropdown-order-content {
      display: flex;
    }
  }

  .ikt-ws_dropdown-order-trigger {
    position: relative;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 12px 35px 12px 12px;

    .ikt-ws_dropdown-order-trigger-left-caption {
      padding-right: 25px;
      font-size: 16px;
      font-weight: bold;
      color: #000000;
      line-height: 19px;
    }

    .ikt-ws_dropdown-order-trigger-order-state {
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;

      &.ikt-ws_in-transit {
        color: #1899a5;
      }

      &.ikt-ws_manufacturing {
        color: #152090;
      }

      &.ikt-ws_delivered {
        color: #b07635;
      }
    }

    .ikt-ws_dropdown-order-chevron {
      position: absolute;
      top: 17px;
      right: 15px;
    }
  }

  .ikt-ws_dropdown-order-content {
    display: none;
    flex-direction: column;
    color: #000;
    padding: 0 15px 15px 15px;

    .ikt-ws_dropdown-order-order-items {
      display: flex;
      width: 100%;
      flex-direction: column;

      .product:first-child {
        margin-top: 0;
      }
    }

    .ikt-ws_dropdown-order-order-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 10px;

      .ikt-ws_dropdown-order-order-button {
        margin-left: 15px;
        width: 222px;
      }

      .ikt-ws_dropdown-order-order-button:last-child {
        margin-right: 0;
      }
    }
  }
}
