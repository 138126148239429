.ikt-ws_shop-page-container {
  padding: 0 120px;

  .ikt-ws_shop-page-top {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 38px;

    .ikt-ws_shop-page-title {
      font-size: 40px;
      font-weight: bold;
      color: #000000;
      line-height: 49px;
    }

    .ikt-ws_dropdown.ikt-ws_shop-page-section-dropdown {
      width: 175px;
      height: 29px;

      .ikt-ws_dropdown-trigger {
        button.ikt-ws_dropdown-button {
          padding: 4px 34px 4px 12px;
        }
      }

      .ikt-ws_dropdown-content {
        .ikt-ws_dropdown-item {
          padding: 6px 14px;
        }
      }
    }
  }

  .ikt-ws_filters-n-products-container {
    display: flex;
    flex-direction: row;
    min-width: 600px;
    margin-top: 30px;

    .ikt-ws_filters-container {
      width: 233px;
    }

    .ikt-ws_products-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, 241px);
      grid-template-rows: repeat(auto-fill, minmax(361px, max-content));
      justify-content: space-between;
      width: 100%;

      .ikt-ws_shop-page-product-card {
        margin-left: 8px;
        margin-bottom: 18px;
      }
    }
  }

  .ikt-ws_shop-page-no-products {
    display: flex;
    justify-content: center;
    font-size: 25px;
    color: #000000;
    line-height: 25px;
    width: 100%;
    padding-top: 50px;
  }

  .ikt-ws_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 192px);
    width: 100%;
  }
}
