@import '../../../scss/common';

.ikt-ws_input-image-container {
  display: flex;
  position: relative;
  flex-direction: column;

  &.error {
    .ikt-ws_input-image {
      border-color: #9d0006 !important;
    }
  }

  .ikt-ws_input-image-image {
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }

  .ikt-ws_input-image {
    outline: unset;
    padding: 9px 12px 9px 39px;
    border-radius: 20px;
    border: 1px solid $inputMainStrokeColor;
    background-color: $inputFillColor !important;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: $inputMainTextColor;
    line-height: 18px;
  }

  .ikt-ws_input-image:hover {
    border-color: $inputHoverStrokeColor;
  }

  .ikt-ws_input-image:focus {
    border-color: $inputFocusStrokeColor;
  }

  .ikt-ws_input-image[disabled] {
    color: $inputDisabledMainTextColor;
    border-color: $inputDisabledStrokeColor;
    background-color: $inputDisabledFillColor;
  }

  .ikt-ws_input-image::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $inputPlaceholderTextColor;
    opacity: 1; /* Firefox */
    font-family: 'Montserrat', sans-serif;
    letter-spacing: normal;
    font-size: 14px;
  }

  .ikt-ws_input-image:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $inputPlaceholderTextColor;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: normal;
    font-size: 14px;
  }

  .ikt-ws_input-image::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $inputPlaceholderTextColor;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: normal;
    font-size: 14px;
  }

  .ikt-ws_input-image[disabled]::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $inputDisabledPlaceholderTextColor;
    opacity: 1; /* Firefox */
  }

  .ikt-ws_input-image[disabled]:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $inputDisabledPlaceholderTextColor;
  }

  .ikt-ws_input-image[disabled]::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $inputDisabledPlaceholderTextColor;
  }
}
