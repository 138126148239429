.cabinet-details {
  min-height: 500px;
  .cabinet-img {
    border-radius: 5px;
  }

  .cabinet-title {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .custom-btn {
    background-color: #9ac3e5;
    border-radius: 15px;
    color: white;
    font-size: 14px;
    padding: 2px;
    text-align: center;
    height: 25px;
    width: 71px;
  }

  .cabinet-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 350px;
    width: 100%;
  }

  .product-size {
    margin-bottom: 10px;
  }

  .product-settings {
    margin-bottom: 10px;
  }

  .edit-btn {
    min-width: 216px;
  }

  .add-to-cart-btn {
    min-width: 216px;
  }

  .note-title {
    font-size: 26px;
  }

  .note-text {
    font-size: 14px;
  }
}

.settings-card {
  background-color: #f4faff;
  border: 1px solid #bfd9ef;
  border-radius: 5px;
  padding: 10px;
  height: 140px;
  width: 270px;

  .setting-card-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .setting-card-name {
    font-size: 14px;
    margin-bottom: 15px;
  }
}
