.ikt-ws_thank-you-page-container {
  &.authorized {
    .ikt-ws_thank-you-page-bottom {
      .ikt-ws_thank-you-page-make-another-cab-container {
        margin-bottom: 13px;
      }
    }
  }

  .ikt-ws_thank-you-page-top {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 233px;
    background-color: #e9eff4;
    margin-bottom: 176px;
    padding-top: 37px;
  }

  .ikt-ws_thank-you-page-bottom {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 30px 32px 30px;

    .ikt-ws_thank-you-page-heading,
    .ikt-ws_thank-you-page-text,
    .ikt-ws_thank-you-page-review-cart-container,
    .ikt-ws_thank-you-page-make-another-cab-container,
    .ikt-ws_thank-you-page-browse-my-cab-container {
      display: flex;
      justify-content: center;
    }

    .ikt-ws_thank-you-page-heading {
      font-size: 40px;
      color: #000000;
      line-height: 49px;
      font-weight: bold;
      margin-bottom: 21px;
    }

    .ikt-ws_thank-you-page-text {
      font-size: 14px;
      color: #000000;
      line-height: 25px;
      margin-bottom: 32px;
    }

    .ikt-ws_thank-you-page-review-cart-btn,
    .ikt-ws_thank-you-page-make-another-cab-btn,
    .ikt-ws_thank-you-page-browse-my-cab-btn {
      width: 244px;
      height: 38px;
      padding: 0;
    }

    .ikt-ws_thank-you-page-make-another-cab-btn {
      background-color: #9ac3e5;
    }

    .ikt-ws_thank-you-page-review-cart-container {
      margin-bottom: 44px;
    }

    .ikt-ws_thank-you-page-make-another-cab-container {
      margin-bottom: 0px;
    }
  }

  .ikt-ws_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 112px);
    width: 100%;
  }

  .sign-up-container {
    margin-top: 0;
  }
}
