@import '../../../scss/common';

.ikt-ws_button.teal {
  background-color: $tealMainBgColor;
  color: #fff;
}

.ikt-ws_button.teal:hover {
  background-color: $tealHoverBgColor;
  color: #fff;
}

.ikt-ws_button.teal.disabled,
.ikt-ws_button.teal.disabled:hover {
  background-color: rgba($tealMainBgColor, 0.5);
  cursor: default;
}
