@import '../../../scss/common';

.ikt-ws_button.shaded {
  background-color: $shadedMainBgColor;
  color: $shadedMainTextColor;
}

.ikt-ws_button.shaded:hover {
  background-color: $shadedHoverBgColor;
  color: $shadedMainTextColor;
}

.ikt-ws_button.shaded.disabled,
.ikt-ws_button.shaded.disabled:hover {
  background-color: rgba($shadedMainBgColor, 0.5);
  color: rgba($shadedMainTextColor, 0.5);
  cursor: default;
}
