.ikt-ws_my-orders-page-container {
  padding: 34px 120px 0 120px;
  min-height: calc(100vh - 192px);

  .ikt-ws_my-orders-page-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;

    .ikt-ws_my-orders-page-heading {
      font-size: 40px;
      color: #000000;
      line-height: 49px;
      font-weight: bold;
    }

    .ikt-ws_my-orders-page-filters {
      display: flex;

      .ikt-ws_my-orders-page-name-search {
        width: 263px;
      }

      .ikt-ws_my-orders-page-items-order-filter {
        width: 186px;
        margin-left: 12px;
      }
    }

    .ikt-ws_my-orders-page-name-search {
      width: 263px;
    }
  }

  .ikt-ws_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 192px);
    width: 100%;
  }
}
