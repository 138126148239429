@import '../../../scss/common';

.ikt-ws_button.link-empty {
  background-color: unset;
  color: $linkEmptyMainTextColor;
}

.ikt-ws_button.link-empty:hover {
  color: $linkEmptyHoverTextColor;
}

.ikt-ws_button.link-empty.disabled,
.ikt-ws_button.link-empty.disabled:hover {
  color: $linkEmptyDisabledTextColor;
  cursor: default;
}
