.ikt-ws_contacts-page-container {
  display: flex;
  flex-direction: column;

  .ikt-ws_contacts-page-top {
    padding: 0 120px;
    padding-bottom: 56px;
    .ikt-ws_contacts-page-title {
      padding: 28px 0;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 49px;
      /* identical to box height */

      color: #2f2f2f;
    }

    .itk-ws_contacts-page-description {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;
      /* or 144% */

      color: #686868;
    }
  }
}

.ikt-ws_contacts-page-location {
  padding: 0 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .contacts-details {
    display: flex;
    flex-direction: column;
    .details {
      display: flex;
      flex-direction: row;
      padding-bottom: 20px;

      .details-content {
        display: flex;
        flex-direction: column;
        padding-left: 21px;

        span {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 28px;
          /* or 175% */

          color: #686868;
        }
      }
    }
    .web {
      align-items: flex-start;
      .details-content {
        padding-top: 10px;

        .social-media {
          padding-bottom: 10px;
          display: flex;
          align-items: center;
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 28px;
          /* identical to box height, or 175% */

          color: #1899a5;

          img {
            padding-right: 9px;
          }
        }
      }
    }
  }

  .contacts-map {
    margin-left: 32px;
    .google-map {
      width: 55vw;
      height: 379px;
      border-radius: 20px;
      overflow: hidden;
    }
  }
}

@media (max-width: 1380px) {
  .ikt-ws_contacts-page-location {
    flex-direction: column;
    align-items: center;
  }
}