.header-dark-theme {
  color: white !important;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: transparent !important;
}

.header-light-theme {
  background-color: #f4faff;
  color: #282828;
}

.navbar {
  background-color: transparent !important;
}

.menu-bar {
  border-top: 1px solid #d0d5da;
  padding: 0 120px;

  .logo {
    padding: 20px 0 10px 0;
  }

  .dark-items {
    a {
      color: white;
    }
  }

  .navbar-burger {
    position: relative;
    top: 10px;

    span {
      background-color: white !important;
    }
  }

  .navbar-menu.is-active {
    background-color: #16191b !important;

    .navbar-item {
      text-align: center;

      a {
        button {
          margin: 0 auto;
        }
      }
    }
  }

  .cabinet-btn {
    background-color: #b07635;
    border-color: #b07635;
    font-family: 'Red Hat Display';
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    line-height: 21px;
  }

  .navbar-item {
    background-color: transparent !important;
    font-family: 'Red Hat Display';
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;

    .cart-icon {
      position: relative;

      .cart-badge {
        background-color: #dc5022;
        border-radius: 50%;
        color: white;
        font-size: 10px;
        text-align: center;
        top: 0px;
        left: 15px;
        height: 15px;
        position: absolute;
        width: 15px;
      }
    }

    .user-image-icon {
      position: relative;
    }
  }
}

.top-bar {
  padding: 5px 120px;
  font-family: 'Red Hat Display';
  font-size: 12px;

  .note-ad {
    text-align: center;
  }

  /*.contacts {
    
  }*/

  .language-dropdown {
    padding-top: 7px;
  }

  .language-name {
    font-weight: bold;
    padding-left: 5px;
    margin-bottom: 5px;
  }
}

.footer {
  background-color: #f4faff !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.footer-bar {
  border-top: 1px solid #d0d5da;
  font-family: Montserrat;
  font-size: 12px;
  padding: 20px 39px 20px 200px;
  max-height: 352px;

  .socials {
    margin: auto;
    padding-top: 15px;

    a {
      margin: 0 10px;
    }
  }

  .bold-text {
    font-weight: bold;
  }
}

.footer-menu {
  font-family: Montserrat;
  font-size: 14px;
  line-height: 24px;
  color: #302929;
  padding-left: 200px;
  padding-right: 39px;
  padding-top: 40px;

  .footer-group-name {
    font-weight: bold;
  }

  .footer-navbar-item {
    font-family: Montserrat;
    font-size: 14px;
    line-height: 24px;
    color: #302929;
  }
}

@media (max-width: 768px) {
  .footer-bar {
    padding-left: 40px !important;
    text-align: center;
  }

  .footer-menu {
    padding-left: 40px !important;
    text-align: center;
  }
}
