.cart-page {
  padding-left: 120px;
  min-height: 500px;

  .cart-content {
    .checkout-btn {
      padding: 10px 18px;
      max-width: 210px;
    }
  }

  .cart-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .order-sum {
    background-color: #e9eff4;
  }
}

.order-sum-page {
  .checkout-btn {
    padding: 11px 18px;
    max-width: 210px;
    margin: 0 auto;
  }
}

.cart-page-title {
  display: flex;
  justify-content: space-between;
  .title {
    align-items: center;
    display: flex;
    .item-count {
      border-radius: 50%;
      background-color: #1899a5;
      color: white;
      font-size: 15px;
      height: 22px;
      padding: 3px;
      margin-left: 10px;
      text-align: center;
      width: 22px;
    }

    span {
      font-family: Montserrat;
      font-size: 40px;
      color: #000000;
      line-height: 49px;
    }
  }

  .order-nmb {
    align-items: center;
    display: flex;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    line-height: 19px;
  }
}

.order-sum-page {
  background-color: rgba(167, 171, 175, 0);
  font-family: Montserrat;
  color: #000000;
  max-width: 250px;
  margin: 0 auto;

  .order-sum-title {
    font-size: 40px;
    font-weight: bold;
    line-height: 40px;
    margin: 35px 0;
  }

  .order-sum-content {
    font-size: 16px;
    font-weight: bold;
    line-height: 34px;
    margin-bottom: 35px;

    .order-sum-item {
      display: flex;
      justify-content: space-between;

      .sum-value {
        font-weight: 100;
      }
    }
  }
}

.cart-item {
  .cart-item-total {
    display: flex;
    justify-content: flex-end;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    margin: 20px 0;
    padding-right: 270px;
  }

  .cart-dropdown-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.cart-products {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e9eaeb;

  .products-estimates {
    .delivery-est {
    }

    .sub-shipping {
    }
  }
}

.product {
  border: 1px solid #bfd9ef;
  border-radius: 6px;
  background-color: #f4faff;
  font-family: Montserrat;
  margin: 5px 0;
  width: 100%;

  .product-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 14px;

    .product-img {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      height: 49px;
      width: 63px;
      border-radius: 12px;
    }

    .product-title {
      .title {
        font-size: 16px;
        margin-bottom: 0;
      }

      .description {
        opacity: 0.5;
      }
    }

    .product-count {
      align-items: center;
      border: 1px solid #e9eaeb;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      width: 77px;
      min-height: 40px;

      .count-increase {
        img {
          padding-top: 5px;
        }
      }

      .count-decrease {
        img {
          padding-bottom: 3px;
        }
      }

      .count-input {
        input {
          background-color: #f5f6f7;
          border: none;
          max-width: 25px;
          outline: none;
          padding: 0 3px;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    .product-color {
      display: flex;
      .color-selection {
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background-color: #886161;
        margin-left: 10px;
      }
    }

    .product-price {
      font-size: 14px;
    }

    .product-total-price {
      font-weight: bold;
    }

    .product-remove-btn {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.products-estimates {
  font-family: Montserrat;
  font-size: 14px;

  .del-est-text {
    font-weight: bold;
  }
}
