.ikt-ws_similar-products-slider {
  margin-top: 4px;

  .ikt-ws_similar-products-slider-next-arrow,
  .ikt-ws_similar-products-slider-prev-arrow {
    display: block;
    position: relative;
    top: -2px;
    width: 200px;
    padding-left: 4px;
    padding-right: 4px;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    img {
      height: auto;
      width: 24px;
    }
  }

  .slick-slider {
    margin: 0 auto;
    max-width: 1100px;

    .slick-list {
      margin: 0 32px;
    }
  }
}
