.ikt-ws_select-cabinet-manufacturer-page-container {
  .ikt-ws_select-cabinet-manufacturer-page-top {
    display: flex;
    min-height: calc(100vh - 192px);

    .ikt-ws_select-cabinet-manufacturer-left-col {
      background-color: #d4dfe9;
      padding: 26px;
    }

    .ikt-ws_select-cabinet-manufacturer-right-col {
      width: 100%;
      padding: 35px 29px 0 29px;

      .ikt-ws_select-cabinet-manufacturer-right-col-heading {
        font-size: 40px;
        color: #000000;
        line-height: 49px;
        font-weight: bold;
        margin-bottom: 35px;
      }
    }
  }

  .ikt-ws_select-cabinet-manufacturer-page-bottom {
    display: flex;
    justify-content: space-between;
    padding: 24px 120px;
    background-color: #e9eaeb;

    .ikt-ws_select-cabinet-manufacturer-cancel-btn,
    .ikt-ws_select-cabinet-manufacturer-add-to-cart-btn {
      width: 122px;
      height: 38px;
      padding: 0;
    }
  }

  .ikt-ws_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 112px);
    width: 100%;
  }
}
