@import '../../../../scss/common';

.ikt-ws_shop-filter-chips-content {
  display: none;
  flex-wrap: wrap;
  color: #000;
  padding-top: 10px;

  .ikt-ws_shop-chips-filter-item,
  .ikt-ws_shop-filter-all-item {
    display: flex;
    cursor: pointer;
    margin-right: 4px;
    margin-bottom: 3px;
    background-color: #e9eaeb;
    font-size: 14px;
    font-weight: bold;
    border-radius: 20px;
    padding: 1px 9px;

    &:hover {
      color: #fff;
      background-color: #000;
    }

    &.ikt-ws_is-active {
      color: #fff;
      background-color: #000;
    }
  }

  .ikt-ws_shop-filter-all-item {
    padding: 1px 12px;
  }
}
