.ikt-ws_manufacturer-list-item-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid #bfd9ef;
  border-radius: 12px;
  margin-bottom: 6px;
  background-color: #f4faff;
  padding-right: 79px;

  &.active {
    border-color: #1899a5;
    background-color: #fff;
  }

  .ikt-ws_manufacturer-list-item-left-part {
    display: flex;
    width: 35%;
    align-items: center;

    &.active {
      .ikt-ws_manufacturer-list-item-title-n-city {
        .ikt-ws_manufacturer-list-item-title {
          font-weight: bold;
        }
      }
    }

    .ikt-ws_manufacturer-list-item-image-container {
      margin: 7px 0 7px 7px;
      padding: 0 4px;
      border-radius: 5px;
      background-color: #fff;

      .ikt-ws_manufacturer-list-item-image {
        width: 89px;
        height: 56px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 50%;
      }
    }

    .ikt-ws_manufacturer-list-item-title-n-city {
      margin-left: 21px;

      .ikt-ws_manufacturer-list-item-title {
        font-size: 16px;
        color: #000000;
        line-height: 19px;
        font-weight: bold;
        margin-bottom: 5px;
      }

      .ikt-ws_manufacturer-list-item-city {
        font-size: 14px;
        color: #1899a5;
        line-height: 18px;
        color: #1899a5;
      }
    }
  }

  .ikt-ws_manufacturer-list-item-delivery-date,
  .ikt-ws_manufacturer-list-item-delivery-price,
  .ikt-ws_manufacturer-list-item-cabinet-price,
  .ikt-ws_manufacturer-list-item-total-price {
    font-size: 14px;
    color: #000000;
    line-height: 18px;

    &.active {
      font-weight: bold;
    }
  }

  .ikt-ws_manufacturer-list-item-delivery-date {
    width: 17%;
  }

  .ikt-ws_manufacturer-list-item-delivery-price {
    width: 17%;
  }

  .ikt-ws_manufacturer-list-item-cabinet-price {
    width: 17%;
  }

  .ikt-ws_checkbox-manufacturer {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
}
