.ikt-ws_my-cabinets-page-container {
  padding: 34px 120px 0 120px;
  min-height: calc(100vh - 192px);

  .ikt-ws_my-cabinets-page-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;

    .ikt-ws_my-cabinets-page-heading {
      font-size: 40px;
      color: #000000;
      line-height: 49px;
      font-weight: bold;
    }

    .ikt-ws_my-cabinets-page-filters {
      display: flex;

      .ikt-ws_my-cabinets-page-name-search {
        width: 263px;
      }

      .ikt-ws_my-cabinets-page-items-order-filter {
        width: 186px;
        margin-left: 12px;
      }
    }

    .ikt-ws_my-cabinets-page-name-search {
      width: 263px;
    }
  }

  .ikt-ws_my-cabinets-page-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, 241px);
    grid-template-rows: repeat(auto-fill, minmax(357px, max-content));
    justify-content: space-between;
    margin-right: -9px;

    .ikt-ws_ikt-ws_cabinet-item-container {
      margin-right: 8px;
      margin-bottom: 18px;
    }
  }

  .ikt-ws_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 192px);
    width: 100%;
  }
}
