.ikt-ws_gallery-page-container {
    .ikt-ws_gallery-page-content {
      padding: 0 240px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
  
      .ikt-ws_gallery-page-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 49px;
        margin: 28px 0;
        text-align: center;
        color: #2f2f2f;
      }
  
      .ikt-ws_gallery-page-description {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;
        color: #686868;
      }

      .ikt-ws_gallery-content {
        display: flex;
        flex-direction: row;
        margin-top: 56px;

        .gallery-column {
          .gallery-img {
            width: 100%;
            padding-right: 32px;
            padding-bottom: 32px;
          }
        }
      }
    }
  }
  