@import '../../../../scss/common';

.ikt-ws_select-materials-container {
  display: inline-block;
  vertical-align: top;
  border-radius: 7px;
  border: 1px solid #bfd9ef;
  width: 305px;
  background-color: #f4faff;
  padding: 11px 12px 13px 12px;
  margin-right: 16px;
  margin-bottom: 16px;

  &:last-child {
    margin-right: 0;
  }

  .ikt-ws_select-materials-heading {
    font-size: 16px;
    color: #000000;
    line-height: 19px;
    font-weight: bold;
    margin-bottom: 26px;
  }

  .ikt-ws_select-materials-hint {
    font-size: 14px;
    color: #000000;
    line-height: 18px;
    margin-bottom: 17px;
  }

  .ikt-ws_select-materials-dropdown {
    width: 100%;
  }
}
