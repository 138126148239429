@import '../../../scss/common';

.ikt-ws_button.gold {
  background-color: $goldMainBgColor;
  color: #fff;
}

.ikt-ws_button.gold:hover {
  background-color: $goldHoverBgColor;
  color: #fff;
}

.ikt-ws_button.gold.disabled,
.ikt-ws_button.gold.disabled:hover {
  background-color: rgba($goldMainBgColor, 0.5);
  cursor: default;
}
