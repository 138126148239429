.ikt-faq-page-container {
  .ikt-ws_faq-page-top {
    padding: 0 120px;
    .ikt-ws_faq-page-title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 49px;
      padding: 28px 0;
      color: #2f2f2f;
    }
    .ikt-ws_faq-page-description {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;

      color: #686868;
      padding-bottom: 31px;
    }
  }
  .ikt-ws_faq-page-content {
    padding: 0 120px;
  }
}
