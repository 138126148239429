.ikt-ws_dark-overlay {
  position: absolute;
  visibility: hidden;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  cursor: pointer;

  &.shown {
    visibility: visible;
    background-color: rgba(#000, 0.8);
    transition: all 0.2s;
  }
}
