.order-titles {
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;

  .selected {
    color: black;
  }

  .valid {
    color: #1899a5;
  }

  div {
    cursor: pointer;
    padding: 0 10px;
  }
}

.order-card {
  min-height: 155px;
  border: 2px solid #1899a5;
  border-radius: 5px;
  margin-bottom: 15px;
}

.address-card {
  border: 1px solid #e9eaeb;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  .address-card-title {
    font-size: 16px;
    font-weight: bold;
    margin: 17px 0;
  }

  .address-card-input {
    min-width: 250px;
    margin-bottom: 7px;
  }

  .address-card-dropdown {
    min-width: 250px;
    margin-bottom: 7px;
  }

  .billing-address {
    margin-top: 113px;

    .billing-checkbox {
      display: flex;
      flex-direction: row;

      span {
        font-size: 14px;
        margin-left: 10px;
        opacity: 0.5;
      }
    }
  }
}

.payment-cards {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;

  .payment-cards-btn {
    min-width: 185px;
    margin-right: 20px;
  }

  .payment-cards-btn-passive {
    background-color: #9ac3e5 !important;
    color: white !important;
  }
}

.payment-card {
  font-family: Montserrat;
  display: flex;
  flex-direction: row;
  min-height: 130px;
  border: 2px solid #1899a5;
  border-radius: 5px;
  margin-bottom: 15px !important;

  .payment-input {
    min-width: 250px;
    margin-bottom: 10px;
  }

  .payment-card-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 11px;
  }
  .payment-card-name {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 11px;
  }
}

.payment-card-details {
  border: 1px solid #e9eaeb;
  border-radius: 5px;
  display: flex;
  font-family: Montserrat;
  flex-direction: row;
  margin-bottom: 20px;

  .payment-card-title {
    font-size: 16px;
    font-weight: bold;
  }

  .payment-card-numbers {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    .payment-card-number {
      max-width: 65px;
    }
  }

  .payment-card-date {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    .payment-card-month {
      max-width: 65px;
    }
  }

  .payment-card-input {
    min-width: 260px;
    margin-bottom: 10px;
  }

  .payment-card-input-short {
    width: 130px;
    margin-bottom: 10px;
  }
}

.payment-method-dropdown {
  margin-bottom: 22px;
  .method-title {
    font-size: 16px;
    font-weight: bold;
  }

  .method-name {
    font-size: 14px;
    font-weight: bold;
  }
}

.address-card-dropdown {
  margin-bottom: 22px;

  .address-title {
    font-size: 16px;
    font-weight: bold;
  }

  .address-name {
    font-size: 14px;
  }
}

.thank-you-page {
  min-height: 500px;

  .img-content {
    display: flex;
    min-height: 240px;
    background-color: rgb(212, 223, 233);
    position: relative;

    .success-img {
      position: absolute;
      right: 45%;
      top: 65%;
    }
  }

  .text-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 175px 0;

    .thank-you-title {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .thank-you-text {
      font-size: 14px;
      margin-bottom: 32px;
    }

    .back-to-shop-btn {
      min-width: 250px;
    }
  }
}

.failure-page {
  min-height: 500px;

  .img-content {
    display: flex;
    min-height: 240px;
    background-color: rgba(188, 40, 0, 0.1);
    position: relative;

    .success-img {
      position: absolute;
      right: 45%;
      top: 65%;
    }
  }

  .text-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 175px 0;

    .failure-title {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .failure-text {
      font-size: 14px;
      margin-bottom: 32px;
    }

    .back-to-shop-btn {
      min-width: 250px;
    }
  }
}
