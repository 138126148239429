@import '../../../scss/common';

.ikt-ws_custom-cabinet-container {
  display: inline-block;
  vertical-align: top;
  border-radius: 7px;
  width: 233px;

  .ikt-ws_custom-cabinet-image {
    display: flex;
    width: 100%;
    height: 196px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 7px 7px 0 0;
  }

  .ikt-ws_custom-cabinet-bottom {
    position: relative;
    padding: 15px 10px 15px 10px;
    border-radius: 0 0 7px 7px;
    background-color: #ffffff;
    min-height: 130px;

    .ikt-ws_custom-cabinet-title {
      font-size: 16px;
      color: #000000;
      line-height: 19px;
      font-weight: bold;
    }

    .ikt-ws_custom-cabinet-skeleton-mat-n-size {
      position: relative;
      font-size: 14px;
      color: rgba(#000, 0.5);
      line-height: 18px;
      margin-top: 2px;
      margin-bottom: 14px;
      padding-right: 25px;
    }

    .ikt-ws_custom-cabinet-doors-mat {
      position: relative;
      font-size: 14px;
      color: rgba(#000, 0.5);
      line-height: 18px;
      margin-bottom: 14px;
      padding-right: 20px;
    }

    .ikt-ws_custom-cabinet-handles-mat {
      position: relative;
      font-size: 14px;
      color: rgba(#000, 0.5);
      line-height: 18px;
    }

    .ikt-ws_cabinet-item-color {
      position: absolute;
      top: 0;
      right: 0;

      .ikt-ws_cabinet-item-color-btn-content {
        display: inline-block;
        position: relative;
        cursor: pointer;
        width: 18px;
        height: 18px;
        line-height: 18px;
        border-radius: 50%;

        .ikt-ws_cabinet-item-color-btn-outer {
          position: absolute;
          top: 0;
          left: 0;
          width: 18px;
          height: 18px;
          line-height: 18px;
          border: 1px solid #000;
          border-radius: 50%;
          background-color: #fff;

          .ikt-ws_cabinet-item-color-btn-inner {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 11px;
            height: 11px;
            line-height: 11px;
            border-radius: 50%;
            border: unset;
          }
        }
      }
    }
  }
}
