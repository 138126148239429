.top-container {
  background-image: url('../assets/images/background/main-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 800px;
  margin-bottom: 40px;

  .top-slider {
    padding-top: 330px;
    margin-bottom: 52px;

    .top-slider-item {
      .slider-item-title {
        text-align: center;
        font-family: Montserrat;
        font-size: 46px;
        font-weight: bold;
        color: #ffffff;
        line-height: 57px;
        padding-bottom: 30px;
      }

      .slider-item-text {
        text-align: center;
        font-family: 'Red Hat Display';
        font-size: 18px;
        color: #ffffff;
        line-height: 23px;
        padding-bottom: 30px;
      }

      .slider-item-btn {
        max-width: 267px;
        margin: 0 auto;

        button {
          padding: 10px 18px;
        }
      }
    }

    .slick-dots li {
      height: 8px;
      width: 100px;
    }

    .slick-prev {
      display: none !important;
    }

    .slick-next {
      display: none !important;
    }

    .slick-dots li button {
      height: 3px;
      width: 100px;
      border-radius: 2px;
      padding: 2px;
      background-color: #565455;
    }

    .slick-dots li button:focus,
    .slick-dots li button:hover {
      background-color: #aba9a9;
    }

    .slick-dots li button:before {
      content: none !important;
    }
  }

  @media (max-width: 1024px) {
    .top-slider {
      padding-top: 140px;
    }
  }

  @media (max-width: 768px) {
    .top-slider {
      padding-top: 220px;
    }
  }

  .top-info-tabs {
    background-color: black;
    font-family: 'Red Hat Display';
    max-width: 1200px;
    margin: 0 auto;
    font-size: 16px;
    color: #ffffff;
    line-height: 21px;

    .info-tab {
      margin: 0 5px;
    }
  }
}

.sales-promotions {
  background-color: #2b313f;
  color: white;
  font-family: Montserrat;
  min-height: 86px;

  .sales-promotions-text {
    font-size: 28px;
    line-height: 34px;
  }

  .sales-promotions-input {
    background-color: white;
    border: 1px solid black;
    border-radius: 25px;
    height: 38px;
    min-width: 290px;
    position: relative;

    .sign-up-btn {
      width: 159px;
      height: 36px;
      padding: 0;
      position: absolute;
      top: 0;
      right: 0;
    }

    input {
      border: none;
      font-size: 16px;
      outline: none;
      padding: 6px 0;
      margin-right: 130px;
      width: 131px;
    }
  }
}

.sign-up-container {
  margin-top: 3.7vh;
}

.lg-btn-container {
  margin-top: 7.4vh;
  margin-bottom: 7.4vh;

  padding-left: 11.46vw;
  padding-right: 11.46vw;

  .lg-btn {
    position: relative;
    border-radius: 4px;
    min-height: 160px;
    margin-bottom: 10px;
  }

  .lg-btn:first-of-type {
    margin-right: 2.08vw;
  }

  .lg-btn:last-of-type {
    margin-left: 2.34vw;
  }

  @media (max-width: 768px) {
    .lg-btn:first-of-type {
      margin-right: 0 !important;
      margin-bottom: 30px;
    }

    .lg-btn:last-of-type {
      margin-left: 0 !important;
    }
  }

  .min-col-width {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    min-width: 60px !important;

    img {
      position: absolute;
      top: 40%;
      display: inline-block;
    }
  }

  .cab-hardware-btn {
    background-image: url('../assets/images/background/cabinet-hardware@2x.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px;
    padding: 5px 0;

    .cab-btn-container {
      padding-top: 25px;
      margin: 30px auto;
    }

    .cab-btn-text {
      font-size: 28px;
      color: white;
      font-weight: bold;
      line-height: 34px;
      font-family: Montserrat;
    }
  }

  .led-lights-btn {
    background-image: url('../assets/images/background/led-lights@2x.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px;
    padding: 5px 0;

    .led-btn-container {
      padding-top: 25px;
      margin: 30px auto;
    }

    .led-btn-text {
      font-size: 28px;
      color: white;
      font-weight: bold;
      line-height: 34px;
      font-family: Montserrat;
    }
  }
}

.rendering-cad-quality-features-container {

  .rendering-cad-container {
    margin-top: 6.5vh !important;
    margin-left: 10.42vw !important;
    width: 79.17vw;

    .title {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 4.63vh;
      color: #336d73;
    }
  
    .btn {
      margin-top: 3.0vh;
      padding: 11px 18px;
      width: 170px;
      margin-left: 10px;
    }

    .btn-extra-width {
      min-width: 230px !important;
    }
  }

  @media (max-width: 768px) {
    .rendering-cad-container {
      text-align: center;

      .btn {
        margin-left: auto;
        margin-right: auto;
      }

      img {
        min-width: 450px;
        position: relative;
        left: -10px;
      }
    }
  }

  .quality-features-container {
    margin-top: 6.5vh !important;
    margin-left: 10.42vw !important;
    width: 79.17vw;

    .title {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 4.63vh;
      text-align: center;
      color: #2b313f;
    }

    .area-container {
      position: relative;
      color: white;
      font-size: 16px;
      padding: 3.24vh 30px 30px 30px;
      border-radius: 30px;

      .area-title {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .text-container {
        max-width: calc(100% - 90px) !important;
      }

      .img-container {
        position: absolute;
        top: 0;
        right: 0;

        width: 120px;
        height: 100%;

        img {
          position: relative;
          top: 20px;
          width: 110px;
        }
      }
    }
    
    .area-container:first-of-type {
      margin-left: 30px;
      margin-right: 40px;
      background-color: #58b9c2;
    }

    .area-container:last-of-type {
      margin-left: 40px;
      margin-right: 30px;
      background-color: #9ac3e5;
    }

    @media (max-width: 768px) {
      .area-container:first-of-type {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
  
      .area-container:last-of-type {
        margin-top: 30px;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }
}

.company-philosophy-container {
  margin-top: 9.2vh !important;
  margin-left: 6.25vw !important;
  width: 87.5vw;

  .title {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 4.63vh;
    text-align: center;
    color: #2b313f;
  }

  .area-title {
    color: #1899a5;
    font-size: 28px;
    font-weight: bold;
  }

  .sustainability-container {
    padding: 3.24vh 5.2vw 0 1.56vw;

    .area-body {
      margin-top: 2.3vh;

      p:last-of-type {
        margin-top: 10px;
      }
    }
  }

  @media (max-width: 768px) {
    img.forest-image {
      position: relative;
      left: -20px;
    }

    img.manufacturing-image {
      position: relative;
      top: 10px;
      left: 25px;
    }
    
    .sustainability-container {
      padding-right: 0;
      padding-left: 0;
      padding-top: 0;

      .area-title {
        text-align: center;
      }

      .area-body {
        text-align: center;
      }
    }
  }

  .buy-locally-container {
    margin-top: 11.1vh;
    
    .area-title {
      margin-bottom: 3.24vh;
    }

    .image-container {
      img {
        position: relative;
        top: 1.2vh;
      }
    }

    .text-container {
      max-width: 50.75%;
      padding-left: 5.73vw;
      padding-right: 2.08vw;
    }

    .area-row {
      margin-top: 30px;
      display: flex;

      .img-helper {
        display: inline-block;
        height: 100%;
        padding-left: 0;
        vertical-align: middle;

        img {
          max-width: 88px;
          max-height: 88px;
          vertical-align: middle;
        }
      }

      div {
        padding-left: 30px;

        .p-title {
          margin-top: 10px;
          margin-bottom: 5px;
          font-weight: bold;
        }

        .p-body {
          
        }
      }
    }
  }

  @media (max-width: 768px) {
    .buy-locally-container {
      margin-top: -25px;
      
      .text-container {
        max-width: 100%;
        padding-left: 2.08vw;

        .area-title {
          text-align: center;
          margin-bottom: 0;
        }
      }
    }
  }

}

.another-section-container {
  margin-top: 30px !important;
  padding-bottom: 5.55vh;
  width: 100%;
  background-color: #f4faff;

  background-image: url('../assets/images/home/section-bg-lower-white-triangle.svg');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% 105px;

  .upper-background-container {
    width: 100% !important;
    height: 105px !important;

    background-image: url('../assets/images/home/section-bg-upper-white-triangle.svg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% 105px;
  }

  .inner-section-container {
    padding: 2.78vh 6.51vw 0 6.51vw;

    .title {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 4.62vh;
      text-align: center;
      color: #2b313f;
    }

    .area-title {
      margin-top: 4.62vh;
      margin-bottom: 20px;
      color: #1899a5;
      font-size: 28px;
      font-weight: bold;
    }

    .subtitle-container {
      padding-left: 2.34vw;
      padding-right: 4.68vw;

      .row:last-of-type {
        margin-top: 20px;
      }
    }

    @media (max-width: 768px) {
      img {
        position: relative;
        left: -30px;
      }

      .subtitle-container {
        position: relative;
        top: -100px;
        text-align: center;
        padding-right: 2.34vw;
      }
    }
  }

}

/*.cabinet-container {
  background-image: url('../assets/images/background/background1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  color: #000000;
  font-family: Montserrat;
  margin-top: 0 !important;

  .cabinet-title {
    font-size: 40px;
    font-weight: bold;
  }

  .own-cabinet-point {
    width: 70px !important;
  }

  .own-cabinet-text {
    font-size: 16px;
    padding-top: 25px;
  }

  .own-cabinet-btn {
    padding: 11px 18px;
    max-width: 270px;
    margin-left: 60px;
  }
}*/

.product-slider {
  margin: 0 auto;
  margin-bottom: 4.12vh;
  .slider-title {
    font-family: Montserrat;
    font-size: 40px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 35px;
    text-align: center;
    line-height: 49px;
  }

  .slick-slide {
    padding: 10px;
  }

  .slick-slider {
    margin: 0 auto;
    max-width: 1400px;
  }

  .product {
    background-color: #9b869b;
    border: 1px solid lightgray;
    border-radius: 4px;
    height: 350px;
    width: 233px !important;
    padding: 10px;
  }

  .product-slider-btn {
    padding: 11px 18px;
    max-width: 250px;
    margin: 0 auto;
  }
}

.shop-departments {
  background-color: #f4faff;
  margin: 0 auto;
  margin-bottom: 45px;
  min-height: 390px;

  .dep-title {
    font-family: Montserrat;
    font-size: 40px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 35px;
    text-align: center;
    line-height: 49px;
    padding-top: 50px;
  }

  .dep-content {
    display: flex;
    flex-direction: row;
    font-family: Montserrat;
    margin-bottom: 30px;
    justify-content: space-between;
    padding: 0 200px;

    .dep-card {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 5px;
      position: relative;
      height: 150px;
      width: 190px;

      .dep-card-title {
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        font-size: 16px;
        font-weight: bold;
        min-height: 40px;
        top: 55px;
        text-align: center;
        padding-top: 10px;
        position: absolute;
        opacity: 12.7;
        width: 100%;
      }
    }
  }

  .shop-dep-btn {
    padding: 11px 18px;
    max-width: 270px;
    margin: 0 auto;
  }
}
