@import '../../../scss/common';

.ikt-ws_dropdown-custom {
  border: 1px solid #e9eaeb;
  border-radius: 7px;
  min-width: 200px;
  padding: 12px;

  &.ikt-ws_is-active {
    .ikt-ws_dropdown-content {
      display: flex;
    }
  }

  .ikt-ws_dropdown-custom-trigger {
    position: relative;
    cursor: pointer;

    .ikt-ws_dropdown-custom-trigger_title {
      padding-right: 25px;
      font-size: 16px;
      font-weight: bold;
      color: #000;
    }

    .ikt-ws_dropdown-custom-chevron {
      position: absolute;
      top: 8px;
      right: 3px;
    }
  }

  .ikt-ws_dropdown-content {
    display: none;
    color: #000;
  }
}
