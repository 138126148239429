.ikt-ws_product-image-slider {
  margin-top: 4px;

  .ikt-ws_product-image-slider-next-arrow,
  .ikt-ws_product-image-slider-prev-arrow {
    display: block;
    position: relative;
    top: -2px;
    width: 200px;
    padding-left: 4px;
    padding-right: 4px;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    img {
      height: auto;
      width: 24px;
    }
  }

  .ikt-ws_product-image-slider-image-container {
    height: 75px;
    outline: none;

    &.ikt-ws_is-active,
    &:hover {
      .ikt-ws_product-image-slider-image {
        opacity: 1;
      }
    }

    .ikt-ws_product-image-slider-image {
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50% 50%;
      border-radius: 12px;
      margin-right: 4px;
      opacity: 0.7;
      cursor: pointer;
    }
  }
}
