.ikt-ws_legal-page-container {
  .ikt-ws_legal-page-content {
    padding: 0 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .ikt-ws_legal-page-title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 49px;
      margin: 28px 0;

      text-align: center;

      color: #2f2f2f;
    }

    .ikt-ws_legal-page-description {
      .date {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 26px;
        line-height: 32px;

        color: #2f2f2f;
        margin-bottom: 14px;
      }

      .legal-text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;
        margin: 20px 0;

        color: #686868;
      }

      .legal-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;

        color: #2f2f2f;
      }

      ul {
        list-style-type: disc;
        list-style-position: inside;
        margin-bottom: 20px;
        .highlighted {
          font-weight: 700;
          margin-left: -10px;
        }
      }
      .terms-link {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;

        color: #1899a5;
      }
    }
  }
}
