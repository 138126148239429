@import '../../../scss/common';

.ikt-ws_button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  border-radius: 20px;
  padding: 11px 18px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
